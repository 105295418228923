import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backdropClasses, Box, Card, CardActionArea, CardMedia, Grid, IconButton } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { TableHead, TableRow, tableCellClasses } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import scss from './ToolPPDocking.module.scss'
import scssPP from './ToolPPStructurePrediction.module.scss'
import scssACE from './ToolACEiPP.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import { images } from 'utils/imgs';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import Text, { TagText } from 'components/tag/Text';
import { getKeyByValue } from 'utils/object';
import { spp } from 'utils/network/jsons';
import StyledTableCell from 'components/table/StyledTableCell';
import { StyledTextFieldStandardDark } from 'components/tool/StyledTextField';
import GreenButton from 'components/button/GreenButton';
import MuiLoadingButton from 'components/mui/MuiLoadingButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { checkInvalidSeq, isFASTAformat } from 'utils/general';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTextField,{StyledTextFieldDisable}  from 'components/tool/StyledTextField';
import { files } from 'utils/files';
import { MuiTableCell4Head } from './ToolPPStructurePrediction';
import { texts as textsPP } from './ToolPPStructurePrediction';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_ppd

const jsons = spp.tool.ppsp_manual_result //todo
const texts = {
  // step:
  // title:
  // manual:
  // upload:
  ...textsPP,
  
  bread:  breadData.create('Tools', 'Protein-Peptide Docking', 'Docking'),
  
  manual: {
    ...textsPP.manual,
    step: '1.Please enter protein and peptide sequence in FASTA format. Protein and peptide sequence are separated by a symbol (:).\n2.Peptide sequence length should be between 2 and 8192.\n3.Please enter less than 5 tasks for each prediction.',
  },
  
  upload: {
    ...textsPP.upload,
    step: '1.Please upload a *.fasta (or *.txt) file. Protein and peptide sequence are separated by a symbol (:).\n2.Protein sequence length should be between 2 and 8192.\n3.Please enter less than 100 tasks for each prediction.',
  },
  
  exampleText: `>Docking01
SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYIDRFLSSMSVLRGKLQLVGTAAMLLASKFEEIYPPEVAEFVYITDDTYTKKQVLRMEHLVLKVLAFDLAAPTINQFLTQYFLHQQPANCKVESLAMFLGELSLIDADPYLKYLPSVIAAAAFHLALYTVTGQSWPESLVQKTGYTLETLKPCLLDLHQTYLRAPQHAQQSIREKYKNSKYHGVSLLNPPETLNVHHHHHHH:IHPF
>Docking02
GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGPLYVIVEYASKGNLREYLQARRPPGLEYSYNPSHNPEEQLSSKDLVSCAYQVARGMEYLASKKCIHRDLAARNVLVTEDNVMKIADFGLARDIHHIDYYKKTTNGRLPVKWMAPEALFDRIYTHQSDVWSFGVLLWEIFTLGGSPYPGVPVEELFKLLKEGHRMDKPSNCTNELYMMMRDCWHAVPSQRPTFKQLVEDLDRIVALTSNQE:IHPF
>Docking03
MKHHHHHHHDEVDGMTEYKLVVVGACGVGKSALTIQLIQNHFVDEYDPTIEDSYRKQVVIDGETSLLDILDTAGQEEYSAMRDQYMRTGEGFLLVFAINNTKSFEDIHHYREQIKRVKDSEDVPMVLVGNKSDLPSRTVDTKQAQDLARSYGIPFIETSAKTRQGVDDAFYTLVREIRKHKEK:IH`,
}

//---------------------------------------------------------------------------
export default function ToolPPDocking({ setInfo }) {
  let manualInit = cookie.getCookie(cookie.keys.tool.ppdManualInput)
  
  //Manual input v---------------------------------------------------------------------------
  const jsonsManual = spp.tool.ppsp_manual_result
  const [manualInput, setManualInput] = useState(manualInit)
  const [isInputError, setInputError] = useState(false)
  const [manualInputErrMsg, setManualInputErrMsg] = useState("")
  const handleChangeManual = (value) => {
    // console.log('value', value);
    
    setManualInput(value)
    setInputError(false)
  }
  
  const handleClickManualReset = () => {
    setManualInput('')
    cleanPPDState()
  }
  function cleanPPDState() {
    cookie.setCookie(cookie.keys.tool.ppdManualInput, '')
    cookie.setCookie(cookie.keys.tool.ppdUploadResult, '')
  }
  
  const handleClickManualSubmit = () => {
    const objInvalidChar = checkInvalidSeq(manualInput);
    const objFASTAformat = isFASTAformat(manualInput);
    
    if (objInvalidChar.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objInvalidChar.errMsg)
      setInfo(snackInfo.openError(objInvalidChar.errMsg))
    } else if (objFASTAformat.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objFASTAformat.errMsg)
      setInfo(snackInfo.openError(objFASTAformat.errMsg))
    } else {
      setInputError(false)
      setManualInputErrMsg("")
      
    if (manualInput !== '') {
      cookie.setCookie(cookie.keys.tool.ppspManualInput, manualInput)
    } else {
      setInputError(true)
      }
    }
  }
  
  const handleClickExample = () => {
    setManualInput(texts.exampleText)
  }
  //Manual input ^------------------------------------------------------------------------------
  
  //Upload file v------------------------------------------------------------------------------
  const jsonsUpload = spp.tool.ppsp_upload_result //todo
  const fileInputRef = useRef(null);
  const handleFileSelect = () => { //Browse
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    }
  };
  
  const handleClickFileReset = () => { // Reset
    setFile(null)
    setFileName('');
  }
  
  const handleClickFileUpload = () => {
    if (file === null) {
      setInfo(snackInfo.openError("Please select a *.fasta (or *.txt) file"))
    } else {
      apiFileUpload()
    }
  }
  
  //------------------------------------------------------------------------------
  const [stateList, setStateList] = useState(axiosState.set(false, false, false, 0))
  const navigate = useNavigate()
  let location = useLocation()
  const apiFileUpload = async () => {
    setStateList(axiosState.loadingState())
    const formData = new FormData();
    formData.append('file', file);

    const config = apiConfig.tool.aceipp_anoxpp_upload_result(formData) //todo
    // console.log('config', config);
    axios(config).then((result) => {
        // console.log('result', result);
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        
        let data = result.data
        let dataJson = JSON.stringify(data)
        cookie.setCookie(cookie.keys.tool.ppspUploadResult, dataJson)
        // console.log(data)
        // console.log('dataJson', dataJson);
        
        // navigate(paths.spp.tool.aceipp_anoxpp_ur(jsonsUpload.dataset.AnOxPs))
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  };
  //Upload file ^------------------------------------------------------------------------------
  
  //result table v------------------------------------------------------------------------------
  //result table ^------------------------------------------------------------------------------
 
  const thirdGrid = 5;
  
  //------------------------------------------------------------------------------
  useEffect(() => {
  }, [])
  
  //------------------------------------------------------------------------------
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scssPP.layout}>
        
        <div className={scss.frame_black}>
        {/* Manual input */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.title[0]}
          subtitleBlock = {
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.manual.step}
            </Text>
          }
          mainBlockContent={
            <Grid container spacing={2}>
              <div className={scssPP.manual_rows}>
                <div className={scssPP.button_bar}>
                  <Text className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}
                    onClick={handleClickExample}>
                    {texts.manual.example}</Text>
                </div>
                
                <StyledTextFieldDisable maxRows={8}
                  className={scssACE.example_box}
                  value={texts.exampleText}
                  disabled
                />
                
                <StyledTextField
                  fullWidth
                  MaxRows={400}
                  className={scssPP.input_box}
                  placeholder={texts.manual.searchHint}
                  label={texts.manual.searchHint}
                  required
                  rows={10}
                  value={manualInput}
                  onChange={(event) => { handleChangeManual(event.target.value) }}
                  error={isInputError}
                  helperText={manualInputErrMsg}
                />
                <div className={scssPP.button_bar}>
                  {/* //todo */}
                  <GreenButton onClick={handleClickManualSubmit} disabled={true} >
                    Submit</GreenButton>
                  <GreenButton onClick={handleClickManualReset}>
                    Reset</GreenButton>
                </div>
              </div>
            </Grid>
          }>
        </ToolSingleBlock>
        
        {/* Upload file */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.title[1]}
          subtitleBlock={
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
              {texts.upload.step}
            </Text>
          }
          mainBlockContent={
          <Grid container spacing={2}>
            <div className={scssPP.manual_rows}>
              <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
                <a href={files.root[texts.upload.fileName]} download={texts.upload.fileName}
                  className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}>
                  {texts.upload.file}</a>
              </Text>
              <StyledTextFieldDisable maxRows={8}
                className={scssACE.example_box}
                value={texts.exampleText}
                disabled
              />
                      
                {/* Select a *.fasta (or *.txt) file: */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                    <Grid item>
                      <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.upload.select}</Text>
                    </Grid>
                </Grid>
                
                {/* Browse */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                  <Grid item>
                    <input accept=".fasta, .txt" type="file"
                      hidden
                      className="custom-file-input" data-target="file-uploader" id="file-uploader"
                      ref={fileInputRef}
                      onChange={handleChangeFile}
                    />
                  <MuiLoadingButton
                    onClick={handleFileSelect}
                    disabled={stateList.isLoading}>
                    Browse</MuiLoadingButton>
                  </Grid>
                  <Grid item>
                    
                  </Grid>
                </Grid>
                
                {/* button */}
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item s={thirdGrid}>
                    <div className={scssPP.button_bar}>
                      <MuiLoadingButton
                        onClick={handleClickFileUpload}
                        // disabled={stateList.isLoading || file === null}
                        disabled //todo
                        loading={stateList.isLoading}>
                          Upload</MuiLoadingButton>

                      <GreenButton onClick={handleClickFileReset}>
                        Reset</GreenButton>
                    </div>
                  </Grid>
                </Grid>
            </div>
          </Grid>
        }>
        </ToolSingleBlock>
        </div>
        
      </div>
    </LayoutPageV2>
  )
}
