import _axios from "axios";
import { gitInfo } from "utils/version";
import endpoint from "./apiEndpoint";
import { mock_dev_url, restDevApiUrl, restRelApiUrl } from "./config";
import jsons, { headers } from "./jsons";

//---------------------------------------------------------------------------
const isMock = false;

// console.log('gitInfo.branch', gitInfo.branch);
let restApiUrl = "";

if (isMock) {
  restApiUrl = mock_dev_url;
} else {
  restApiUrl =
    gitInfo.branch === "master" || gitInfo.branch === "hotfix"
      ? restRelApiUrl
      : restDevApiUrl;
}

// const restTimeout = 5000
const restTimeout = 0; //no limit

const axios = (config) => {
  // console.log('restApiUrl', restApiUrl);
  const instance = _axios.create({
    baseURL: config || restApiUrl,
    timeout: restTimeout,
    // CORS BE suggestion
    /* crossOrigin: true,
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "false",
      "Access-Control-Expose-Headers": "X-My-Custom-Header",
    }, */
  });
  // console.log(instance)
  return instance;
};

export class axiosState {
  static set(isLoading, isTimeout, isResultCode200, numResultError) {
    return { isLoading, isTimeout, isResultCode200, numResultError };
  }
  static init() {
    return {
      isLoading: true,
      isTimeout: false,
      isResultCode200: false,
      numResultError: 0,
    };
  }
  static error(isTimeout, numResultError) {
    return {
      isLoading: false,
      isTimeout: isTimeout,
      isResultCode200: false,
      numResultError: numResultError,
    };
  }
  static resultCode200() {
    return {
      isLoading: false,
      isTimeout: false,
      isResultCode200: true,
      numResultError: 0,
    };
  }
  static keepRest(state) {
    if (
      state.isTimeout === false &&
      state.isResultCode200 === false &&
      state.numResultError < 5
    )
      return true;
    else return false;
  }
  static loadingState(state) {
    return {
      isLoading: true,
      isTimeout: false,
      numResultError: 0,
    };
  }
}

export class axiosCatch {
  static getMsg(err) {
    let msg = err.message;
    if (err.response !== undefined) {
      // console.log(err.response.status);
      // console.log(err.status);
      switch (err.response.status) {
        case 400:
        case 401:
        case 500:
          msg = err.response.data ? err.response.data.message : err.statusText;
          break;
        default:
          msg = err.message;
      }
    }
    return msg;
  }
  static getResultCode(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      // console.log(err.response);
      // console.log(code);
    }
    return code;
  }
  static isTimeout(err) {
    if (err.code === "ECONNABORTED")
      //msg === 'timeout of 5000ms exceeded'
      return true;
    return false;
  }
  static needLogin(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      console.log(code, err.response.data);
      if (code === 910 || code === 911 || code === 913)
        return true;
    }
    return false;
  }
}

class axiosConfig {
  static post = (input, url, timeout = restTimeout) => ({
    method: "post",
    url: url,
    headers: headers.json(),
    data: JSON.stringify(input),
    timeout: timeout,
  });
  static postDefault = (input, url) => ({
    method: "post",
    url: url,
    headers: headers.jsonDefault,
    data: JSON.stringify(input),
  });
  static postFile = (data, url) => ({
    method: "post",
    url: url,
    headers: {
      ...headers.json(),
      "Content-Type": "multipart/form-data",
    },
    data: data,
    maxBodyLength: Infinity,
  })
  //---------------------------------------------------------------------------
  static get = (url, timeout) => ({
    method: "get",
    url: url,
    headers: headers.json(),
    timeout: timeout,
  });
  static getDefault = (url) => ({
    method: "get",
    url: url,
    headers: headers.jsonDefault,
  });
}

// -----------------------------------------------------------------------------
// auth config
class auth {
  //1 login
  static login = (input) => axiosConfig.postDefault(input, endpoint.auth.login); //1 login
  //2 logout
  static logout = (token) => ({
    method: "get",
    url: endpoint.auth.logout, //2 logout
    headers: headers.token(token),
  });
  //4 register
  static signup = (input) =>
    axiosConfig.postDefault(input, endpoint.auth.signup); //4 register
  //3	verify_email
  static verify_email = (input) =>
    axiosConfig.postDefault(input, endpoint.auth.verify_email); //3 verify_email
  //16 get_tnc
  static get_tnc = () => axiosConfig.getDefault(endpoint.auth.tnc); //16 get_tnc
}

//---------------------------------------------------------------------------
// site config
class site {
  //78 portal_summary 7.6
  static home_number = () => axiosConfig.getDefault(endpoint.site.home_number);
  // 108 timeline_list
  static timeline_list = (input) => 
    axiosConfig.postDefault(input, endpoint.site.timeline_list);
}

//---------------------------------------------------------------------------
// protein config
class protein {
  //10 proteins_classification_list
  static list = () => axiosConfig.get(endpoint.spp.protein.list); //10 proteins_classification_list
  static list_class_default_token = () =>
    axiosConfig.getDefault(endpoint.spp.protein.class_list_no_hidden_class);
  static list_class = () =>
    axiosConfig.get(endpoint.spp.protein.class_list_no_hidden_class);
  //11 protein_search
  static quick_search = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.search); //11 proteins_search
  //11 protein_search
  static classify_search = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.search); //11 proteins_search
  //11 protein_search
  static source_search = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.search); //11 proteins_search
  //21 peptide_properties
  static id_search = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.id_search); //21 peptide_properties
  //41 cumulative_result
  static fragment_count = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.fragment); //41 cumulative_result
  //44 class_count_result
  static class_count = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.class_count); //44 class_count_result
  static class_graph = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.class_graph);
  static unique_letter = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.unique_letter);
  static frag_graph = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.frag_graph);
  static peptide_list = (input) =>
    axiosConfig.post(input, endpoint.spp.protein.peptide_list);
}

// -----------------------------------------------------------------------------
// peptide config
class peptide {
  //8 peptides_classification_list
  static list = () => axiosConfig.get(endpoint.spp.peptide.list); //8 peptides_classification_list
  static listWithDefaultToken = () =>
    axiosConfig.getDefault(endpoint.spp.peptide.list); // Using in home page
  //5 peptides_quick_search
  static quick_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.quick_search); //5 peptides_quick_search
  //7 peptide_multifunctional_search
  static multi_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.multi_search); //7 peptides_multifunctional_search
  //12 peptides_classification_quick_search
  static quick_class_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.quick_class_search); //12 peptides_classification_quick_search
  //9 peptides_classification_search
  static class_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.class_search); //9 peptides_classification_search
  //6 peptides_advanced_search
  static advanced_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.advanced_search); //6 peptides_advanced_search
  //21 peptide_properties
  static id_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.id_search); //21 peptide_properties
  static id_precursor_result = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.id_precursor_result); //77 peptides_precursor_search
  static id_target_protein = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.id_target_protein); //88 peptide_target_protein
  static id_target_protein_page = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.id_target_protein_page); //89 target_protein_page
  static get_multi_cross = () => axiosConfig.get(endpoint.spp.tool.multi_cross); //46 multi_cross
  static get_multi_cross_function = () =>
    axiosConfig.get(endpoint.spp.tool.multi_cross_function); //47 multi_cross_function
  static fun_relationship = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.fun_relationship); //48 fun_relationship
  //67 fun_relation_detail_search
  static fun_relationship_cross_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.fun_relationship_cross_search);
  //51 classification_advanced_search
  static class_advanced_search = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.class_advanced_search); //51 classification_advanced_search 1.2.1
  //52 pathway 2.1.2
  static pathway = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.pathway);
  static statistical_data = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.statistical_data);
  static statistics_seq_records = () =>
    axiosConfig.get(endpoint.spp.peptide.statistics_list); //8 peptides_classification_list
  static inductive_analysis = (input) =>
    axiosConfig.post(input, endpoint.spp.peptide.inductive_analysis); //74 peptides_inductive_analysis
}

//---------------------------------------------------------------------------
// tool config
class tool {
  // 4.1
  static hotspot_protein_name = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.hotspot_protein); //38 proteins_name
  static hotspot_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.hotspot_result); //29 hotspot_result
  static hotspot_result_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.hotspot_result_chart); //42 multi_hotspot
  static hotspot_highlight = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.hotspot_highlight); //66 hotspot_highlight
  //---------------------------------------------------------------------------
  // 4.2
  static ehp_tool_enzymes_list = () =>
    axiosConfig.get(endpoint.spp.tool.ehp_tool_enzymes_list); //49 enzymatic_tool
  static ehp_tool_enzyme_page = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.ehp_tool_enzyme_page); //50 enzy_page
  static ehp_tool_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.ehp_tool_result); //64 enzymatic_hydrolysis
  static ehp_tool_result_peptide = (input) =>
    axiosConfig.post(
      input,
      endpoint.spp.tool.ehp_tool_result_peptide, //68 enzyme_peptide_result
      // 10000
      0
    );
  //---------------------------------------------------------------------------
  // 4.3
  static bpp_tool_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.bpp_tool_result);
  //---------------------------------------------------------------------------
  // 4.4
  static aasd_tool_upload_file = (data) => ({
    method: "post",
    maxBodyLength: Infinity,
    url: endpoint.spp.tool.aasd_tool_upload,
    headers: {
      ...headers.json(),
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
  static aasd_tool_get_download_file_url = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.aasd_tool_download);
  //---------------------------------------------------------------------------
  // 4.5
  static peptide_calculator_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.peptide_calculator_result); //18 properties_calculator
  //---------------------------------------------------------------------------
  // 4.6
  static statistical_nc_table = () =>
    axiosConfig.get(endpoint.spp.tool.statistical_nc_table); //72 statistical_double_table
  static statistical_a_table = () =>
    axiosConfig.get(endpoint.spp.tool.statistical_a_table); //73 statistical_single_table
  static statistical_nc_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.statistical_nc_chart); //75 statistical_double_graph
  static statistical_a_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.statistical_a_chart); //76 statistical_single_graph
  //---------------------------------------------------------------------------
  // 4.7
  static pdcaas_list = () =>
    axiosConfig.get(endpoint.spp.tool.pdcaas_list); //98 pdcaas_drop_down
  static pdcaas_table = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.pdcaas_table); //97 dpqehn_list
  static pdcaas_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.pdcaas_result); //99 pdcaas_tool
  //---------------------------------------------------------------------------
  // 4.8
  //---------------------------------------------------------------------------
  // 4.9
  static css_tool_input = () =>
    axiosConfig.get(endpoint.spp.tool.css_tool_input); //87 unique_letter_search_input
  static css_tool_result = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.css_tool_result); //86 unique_letter_search (T21)Endpoint - Unique Letter Search
  static css_tool_result_lv1_bar_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.css_tool_result_lv1_bar_chart); //94 duplicate_seq_chart (T22)Endpoint - Duplicate Sequence Chart
  static css_tool_result_lv1_venn_diagram = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.css_tool_result_lv1_venn_diagram); //90 intersection_chart (T25)Endpoint - Intersection Chart
  static css_tool_result_lv1_venn_diagram_all = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.css_tool_result_lv1_venn_diagram_all); //96 intersect_duplicate_chart (T24)Endpoint - Intersect Duplicate Chart
  static css_tool_result_lv2_bar_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.css_tool_result_lv2_bar_chart); //95 protein_duplicate_chart (T23)Endpoint - Protein Duplicate Chart
  //---------------------------------------------------------------------------
  // 4.11 4.12
  static aceipp_anoxpp_seq_chart = (input) =>
    axiosConfig.post(input, endpoint.spp.tool.aceipp_anoxpp_seq_chart); //100 predictor_stat_chart
  static aceipp_anoxpp_pre_pool_table = (input) => 
    axiosConfig.post(input, endpoint.spp.tool.aceipp_anoxpp_pre_pool_table); //101 predictor_pool_list
  static aceipp_anoxpp_manual_result = (input) => 
    axiosConfig.post(input, endpoint.spp.tool.aceipp_anoxpp_manual_result); //102 predictor_manual_input
  static aceipp_anoxpp_manual_mutation = (input) => 
    axiosConfig.post(input, endpoint.spp.tool.aceipp_anoxpp_manual_mutation); //103 predictor_mutation
  static aceipp_anoxpp_upload_result = (data) => 
    axiosConfig.postFile(data, endpoint.spp.tool.aceipp_anoxpp_upload_result); //104 predictor_upload_file
  //------------------------------------------------------------------------------
}

//------------------------------------------------------------------------------
// statistic config
class statistic {
  static portein = (input) =>
    axiosConfig.post(input, endpoint.spp.statistic.portein); //105 statistical PRO05
  static portein_chart = () =>
    axiosConfig.get(endpoint.spp.statistic.portein_chart); //106 stat_chart PRO06
  // static literature_search = (input) =>
  //   axiosConfig.post(input, endpoint.spp.statistic.literature_search); //107 literature_search H03
}

//---------------------------------------------------------------------------
export const apiConfig = {
  auth, //user
  site,
  protein,
  peptide,
  tool,
  statistic,
};

export { axios };
export default axios();
