import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backdropClasses, Box, Card, CardActionArea, CardMedia, Grid, IconButton } from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import { TableHead, TableRow, tableCellClasses } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import scss from './ToolPPStructurePrediction.module.scss'
import scssACE from './ToolACEiPP.module.scss';
import { breadData } from 'components/bread/BreadBarV2';
import { images } from 'utils/imgs';
import LayoutPageV2 from 'layouts/LayoutPageV2';
import LoadingAnime from 'components/LoadingAnime';
import { MuiTableCell, MuiTableContainer, TableHeadRow, TableRow2Body } from 'components/table/MuiTableV2';
import Text, { TagText } from 'components/tag/Text';
import { getKeyByValue } from 'utils/object';
import { spp } from 'utils/network/jsons';
import StyledTableCell from 'components/table/StyledTableCell';
import { StyledTextFieldStandardDark } from 'components/tool/StyledTextField';
import GreenButton from 'components/button/GreenButton';
import MuiLoadingButton from 'components/mui/MuiLoadingButton';
import ToolSingleBlock from 'components/tool/ToolSingleBlock';
import cookie from 'utils/cookie';
import paths from "utils/network/apiPath";
import axios, { apiConfig, axiosCatch, axiosState } from 'utils/network/axios';
import { checkInvalidSeq, isFASTAformat } from 'utils/general';
import SnackBar, { snackInfo, snackType } from 'components/SnackBar';
import StyledTextField,{StyledTextFieldDisable}  from 'components/tool/StyledTextField';
import { files } from 'utils/files';

//---------------------------------------------------------------------------
// http://localhost:3000/tool_ppsp

const jsons = spp.tool.ppsp_manual_result
export const texts = {
  bread:  breadData.create('Tools', 'Protein & Peptide Structure Predicting', 'Structure Prediction'),
  
  step: [
    'Manual input',
    'Upload file',
  ],
  title: [
    'Enter one or more prediction tasks',
    'Or upload a file in FASTA format directly from your local disk',
  ],
  
  manual: {
    searchHint: 'Please enter your peptide sequences in FASTA format',
    step: '1.Please enter protein and peptide sequence in FASTA format.\n2.Peptide sequence length should be between 2 and 8192.\n3.Please enter less than 5 tasks for each prediction.',
    example: '(Example):',
  },
  
  upload: {
    select: 'Select a *.fasta (or *.txt) file: ',
    step: '1.Please upload a *.fasta (or *.txt) file.\n2.Protein sequence length should be between 2 and 8192.\n3.Please enter less than 100 tasks for each prediction.',
    file: '(example.fasta):',
    fileName: 'example.fasta',
  },

  exampleText: `>Sequence01
SVNEVPDYHEDIHTYLREMEVKCKPKVGYMKKQPDITNSMRAILVDWLVEVGEEYKLQNETLHLAVNYI
>Sequence02
GAGVSEYELPEDPRWELPRDRLVLGKPLGEGAFGQVVLAEAIGLDKDKPNRVTKVAVKMLKSDATEKDLSDLISEMEMMKMIGKHKNIINLLGACTQDGPLYVIVEYASKGNLREYLQARRPPGLEYSYNPSHNPEEQLSSKDLVSCAYQVARGMEYLASKKCIHRDLAARNVLVTEDNVMKIADFGLARDIHHIDYYKKTTNGRLPVKWMAPEALFDRIYTHQSDVWSFGVLLWEIFTLGGSPYPGVPVEELFKLLKEGHRMDKPSNCTNELYMMMRDCWHAVPSQRPTFKQLVEDLDRIVALTSNQE
>Sequence03
EYKLVVVGAC`,
  
  // table
  tableHeadCell: jsons.items_text,
  tableBodyCell: jsons.items,
  // tableBodyCellError: jsons.error_items,
  table: {
    title: [
      ' ',
      '#',
      // 'Copies',
      'Sequence',
    ],
  },
}

export const TableHeadAndRow = (props) => (
  <TableHead>
    <TableRow >
      {props.children}
    </TableRow>
  </TableHead>
)

export const MuiTableCell4Head = (props) => {
  const {children, index, cell1stAlignRight, alignLeft, ...other} = props
  let align = "center"
  if( cell1stAlignRight !== undefined && index === 0)
    align = "right"
  if( alignLeft !== undefined )
    align = 'left'
  
  return (
    <MuiTableCell align={align}  {...other}>
      {children}
    </MuiTableCell>)
}

export const TableCell4HeadTop = (props) => {
  let style = {fontSize: 16,}
  if(props.style !== undefined)
    Object.assign(style, props.style)
  // console.log(style);
  return (
    <MuiTableCell4Head align="center" style={{style}} {...props}>
      {props.children}
    </MuiTableCell4Head>)
}

//---------------------------------------------------------------------------
export default function ToolPPStructurePrediction({ setInfo }) {
  let manualInit = cookie.getCookie(cookie.keys.tool.ppspManualInput)
  
  //Manual input v---------------------------------------------------------------------------
  const jsonsManual = spp.tool.ppsp_manual_result
  const [manualInput, setManualInput] = useState(manualInit)
  const [isInputError, setInputError] = useState(false)
  const [manualInputErrMsg, setManualInputErrMsg] = useState("")
  const handleChangeManual = (value) => {
    // console.log('value', value);
    
    setManualInput(value)
    setInputError(false)
  }
  
  const handleClickManualReset = () => {
    setManualInput('')
    cleanPPSPState()
  }
  function cleanPPSPState() {
    cookie.setCookie(cookie.keys.tool.ppspManualInput, '')
    cookie.setCookie(cookie.keys.tool.ppspUploadResult, '')
  }
  
  const handleClickManualSubmit = () => {
    const objInvalidChar = checkInvalidSeq(manualInput);
    const objFASTAformat = isFASTAformat(manualInput);
    
    if (objInvalidChar.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objInvalidChar.errMsg)
      setInfo(snackInfo.openError(objInvalidChar.errMsg))
    } else if (objFASTAformat.isInvalid) {
      setInputError(true)
      setManualInputErrMsg(objFASTAformat.errMsg)
      setInfo(snackInfo.openError(objFASTAformat.errMsg))
    } else {
      setInputError(false)
      setManualInputErrMsg("")
      
    if (manualInput !== '') {
      cookie.setCookie(cookie.keys.tool.ppspManualInput, manualInput)
    } else {
      setInputError(true)
      }
    }
  }
  
  const handleClickExample = () => {
    setManualInput(texts.exampleText)
  }
  //Manual input ^------------------------------------------------------------------------------
  
  //Upload file v------------------------------------------------------------------------------
  const jsonsUpload = spp.tool.ppsp_upload_result //todo
  const fileInputRef = useRef(null);
  const handleFileSelect = () => { //Browse
    fileInputRef.current.click();
  };

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFileName(file.name);
    }
  };
  
  const handleClickFileReset = () => { // Reset
    setFile(null)
    setFileName('');
  }
  
  const handleClickFileUpload = () => {
    if (file === null) {
      setInfo(snackInfo.openError("Please select a *.fasta (or *.txt) file"))
    } else {
      apiFileUpload()
    }
  }
  
  //------------------------------------------------------------------------------
  const [stateList, setStateList] = useState(axiosState.set(false, false, false, 0))
  const navigate = useNavigate()
  let location = useLocation()
  const apiFileUpload = async () => {
    setStateList(axiosState.loadingState())
    const formData = new FormData();
    formData.append('file', file);

    const config = apiConfig.tool.aceipp_anoxpp_upload_result(formData) //todo
    // console.log('config', config);
    axios(config).then((result) => {
        // console.log('result', result);
      if (result.data.result_code !== 200) {
        setStateList(axiosState.error(false, stateList.numResultError + 1))
        setInfo(snackInfo.openError(result.data.message))
      } else {
        setStateList(axiosState.resultCode200())
        
        let data = result.data
        let dataJson = JSON.stringify(data)
        cookie.setCookie(cookie.keys.tool.ppspUploadResult, dataJson)
        // console.log(data)
        // console.log('dataJson', dataJson);
        
        // navigate(paths.spp.tool.aceipp_anoxpp_ur(jsonsUpload.dataset.AnOxPs))
      }
    }).catch(err => {
      setStateList(axiosState.error(axiosCatch.isTimeout(err), stateList.numResultError + 1))
      setInfo(snackInfo.openError(axiosCatch.getMsg(err)))
      if (axiosCatch.needLogin(err))
        cookie.removeCookieAndJump(navigate, location)
    })
  };
  //Upload file ^------------------------------------------------------------------------------
  
  //result table v------------------------------------------------------------------------------
  const keyOutput = jsons.output 
  const [tableOutput, setTableOutput] = useState({})
  const [tableItems, setTableItems] = useState([])
  const [errorItems, setErrorItems] = useState([])
  const [isNoErrorItems, setIsNoErrorItems] = useState(true)
  const [stateTable, setStateTable] = useState(axiosState.init())
  const apiTableResult = () => {
    setStateTable(axiosState.loadingState())
    
    let data = {}
    setTableOutput(data)
    // console.log(data)
    
    let items = data[spp.common.output.items]
    if(items !== undefined)
      setTableItems(items)
    // console.log('items', items);
    
    let isNoErr = true
    let itemsErr = data[keyOutput[6]] //"error_item(s)"
    if(itemsErr !== undefined && (Array.isArray(itemsErr) && itemsErr.length > 0)) {
      setErrorItems(itemsErr)
      isNoErr = false
    }
    setIsNoErrorItems(isNoErr)
    // console.log('itemsErr', itemsErr);
    // console.log('isNoErr', isNoErr);
    
    setStateTable(axiosState.resultCode200())
  }
  
  function getResultCellAlign(index, title) {
    if(title === 'Name') //'Name',
      return 'left'
    return 'center'
  }
  function getResultCellStyle(index, title) {
    let style = {}
    switch(title) {
      case 'Modified':
        style = { width: 400 }
        break
      case 'State':
        style = { width: 30 }
        break
    }
    return style
  }
  
  const TableBodyCell = (props) => {
    const { tableBodyCell, children, index } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('children', props.children);
    let isDownload = false
    let value, text, align, rowSpan = 1, name
    return (
      tableBodyCell.map((cell, indexX) => {
        value = children[cell]
        text = value
        if(text === undefined)
          text = '-'
        // console.log('cell', cell, 'value', value);
        
        align = 'center'
        if(indexX === 0 ) //Data Sets
          align = 'right'
        if(cell === "name" ) { //Name
          align = 'left'
        }
        if(cell === "link") { //Download
          isDownload = true
          name = children['name']
        }
        
        return (
          <MuiTableCell key={index+''+indexX+''+cell} align={align} rowSpan={rowSpan}
            style = {{paddingTop: '2px', paddingBottom: '2px', height: '32px'}}>
            {isDownload
            ? cellDownload(value, name)
            : text}
          </MuiTableCell>)
      }))
  }
  
  const cellDownload = (file, name) => {
    // console.log(name);
    // console.log(file);
    if( file !== undefined && file !== null ) {
      return (
        <a href={file} download={name} target="_blank">
          <CloudDownloadOutlinedIcon />
        </a>)
    } else
      return null
  }
  
  /* const TableRowBodyCellErorr = (props) => {
    const { tableBodyCell, items } = props
    // console.log('tableBodyCell', props.tableBodyCell);
    // console.log('items', props.items);
    let _style = {paddingTop: '2px', paddingBottom: '2px', height: '32px'}
    return (
      <TableRow2Body>
        <MuiTableCell align={'right'} rowSpan={1} style={_style}>
          {words.dstasets_error}
        </MuiTableCell>
        <MuiTableCell align={'left'} style={_style}>
          <Text pre>{cellErrorText(tableBodyCell, items)}</Text>
        </MuiTableCell>
      </TableRow2Body>)
  }
  
  function cellErrorText(tableBodyCell, items) {
    let value, text, ans = ''
    if(Array.isArray(items)) {
      items.forEach((item) => {
        tableBodyCell.forEach((cell, indexX) => {
            value = item[cell]
            text = value + '\n'
            if(indexX === 0)
              ans += text
            else
              ans += '    ' + text
        })
    })}
    return ans
  } */
  // result ^^^
 
  const thirdGrid = 5;
  const btnStyle = { marginRight: '10px', backgroundColor: '#dfdfdf', color: '#2c2c2c', width: '90px' }
  
  //------------------------------------------------------------------------------
  useEffect(() => {
    apiTableResult()
  }, [])
  
  const tableCol = texts.tableHeadCell.length
  return (
    <LayoutPageV2 bread={texts.bread}>
      <div className={scss.layout}>
        
        {/* Manual input */}
        <ToolSingleBlock stepText={texts.step[0]} title={texts.title[0]}
          subtitleBlock = {
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.manual.step}
            </Text>
          }
          mainBlockContent={
            <Grid container spacing={2}>
              <div className={scss.manual_rows}>
                <div className={scss.button_bar}>
                  <Text className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}
                    onClick={handleClickExample}>
                    {texts.manual.example}</Text>
                </div>
                
                <StyledTextFieldDisable maxRows={8}
                  className={scssACE.example_box}
                  value={texts.exampleText}
                  disabled
                />
                
                <StyledTextField
                  fullWidth
                  MaxRows={400}
                  className={scss.input_box}
                  placeholder={texts.manual.searchHint}
                  label={texts.manual.searchHint}
                  required
                  rows={10}
                  value={manualInput}
                  onChange={(event) => { handleChangeManual(event.target.value) }}
                  error={isInputError}
                  helperText={manualInputErrMsg}
                />
                <div className={scss.button_bar}>
                  {/* //todo */}
                  <GreenButton onClick={handleClickManualSubmit} disabled={true} >
                    Submit</GreenButton>
                  <GreenButton onClick={handleClickManualReset}>
                    Reset</GreenButton>
                </div>
              </div>
            </Grid>
          }>
        </ToolSingleBlock>
        
        {/* Upload file */}
        <ToolSingleBlock stepText={texts.step[1]} title={texts.title[1]}
          subtitleBlock={
            <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
              {texts.upload.step}
            </Text>
          }
          mainBlockContent={
          <Grid container spacing={2}>
            <div className={scss.manual_rows}>
              <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>
                <a href={files.root[texts.upload.fileName]} download={texts.upload.fileName}
                  className={'Contents-QuanticoBody16px-SemiBold-GreenAlpha_75 link'}>
                  {texts.upload.file}</a>
              </Text>
              <StyledTextFieldDisable maxRows={8}
                className={scssACE.example_box}
                value={texts.exampleText}
                disabled
              />
                      
                {/* Select a *.fasta (or *.txt) file: */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                    <Grid item>
                      <Text className={'Contents-QuanticoBody16px-Regular-White_75'}>{texts.upload.select}</Text>
                    </Grid>
                </Grid>
                
                {/* Browse */}
                <Grid container spacing={1} direction="row" alignItems="center" style={{gap: '8px'}}>
                  <Grid item>
                    <input accept=".fasta, .txt" type="file"
                      hidden
                      className="custom-file-input" data-target="file-uploader" id="file-uploader"
                      ref={fileInputRef}
                      onChange={handleChangeFile}
                    />
                  <MuiLoadingButton
                    onClick={handleFileSelect}
                    disabled={stateList.isLoading}>
                    Browse</MuiLoadingButton>
                  </Grid>
                  <Grid item>
                    
                  </Grid>
                </Grid>
                
                {/* button */}
                <Grid container spacing={1} direction="row" alignItems="center">
                  <Grid item s={thirdGrid}>
                    <div className={scss.button_bar}>
                      <MuiLoadingButton
                        onClick={handleClickFileUpload}
                        // disabled={stateList.isLoading || file === null}
                        disabled //todo
                        loading={stateList.isLoading}>
                          Upload</MuiLoadingButton>

                      <GreenButton onClick={handleClickFileReset}>
                        Reset</GreenButton>
                    </div>
                  </Grid>
                </Grid>
            </div>
          </Grid>
        }>
        </ToolSingleBlock>
      
      
      <MuiTableContainer>
          <TableHeadRow> {/* title */}
            {texts.tableHeadCell.map((title, index) => (
              <MuiTableCell4Head key={index} index={index}
                align={getResultCellAlign(index, title)}
                style={getResultCellStyle(index, title)}>
                {title}</MuiTableCell4Head>
            ))}
          </TableHeadRow>
          <TableBody>{/* value */}
            {stateTable.isLoading
            ? <MuiTableCell colSpan={tableCol}>
                <LoadingAnime />
              </MuiTableCell>
            : <>
                {tableItems.length === 0
                ? <TableRow2Body>
                    <TableBodyCell tableBodyCell={texts.tableBodyCell}>-</TableBodyCell>
                  </TableRow2Body>
                : tableItems.map((item, index) => (
                  <TableRow2Body key={index}>
                    <TableBodyCell key={index} index={index} tableBodyCell={texts.tableBodyCell}>
                      {item}
                    </TableBodyCell>
                  </TableRow2Body>
                ))}
              </>}
          </TableBody>
        </MuiTableContainer>
        
      </div>
    </LayoutPageV2>
  )
}
